/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
/* You can add global styles to this file, and also import other style files */
/*
*/
html, body {
  overscroll-behavior: none !important;
  overscroll-behavior-y: contain !important;
}

.selected {
  --bs-table-bg: #0056d6;
  --bs-table-striped-bg: #0056d6;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #0056d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #0056d6;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #0056d6;
}

.dropdown-toggle::after {
  content: none !important;
}
.dropdown-label{
  margin: 0;
  padding: 6px 12px;
  font-size: .813rem;
}
.dropdown-multiselect--active .dropdown-multiselect__caret {
  transform: rotate(180deg);
}
.dropdown-multiselect__caret{
  display: block;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 0px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform .2s ease;
}

.dropdown-multiselect__caret:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #999999 transparent;
  content: "";
}

.bg-teal-one{
  background-color: #B8DED8;
}

.modal{
  z-index: 1090 !important;
}
.text-upper {
  text-transform: uppercase;
}

#right {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.details-widget{
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

html {
  height: 100%;
}
body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.min-table-height {
  min-height: 250px;
}

.list-group-item-accent,
.btn-accent,
.badge-accent {
  background-color: #66b3a6;
  color: white;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.45rem;
  padding-left: 1.5rem;
}

.dp-picker-input {
  border: 0px;
  -webkit-appearance: none;
  max-width: 200px;
}

@include media-breakpoint-only(xs) {
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@include media-breakpoint-only(sm) {
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@include media-breakpoint-only(md) {
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@include media-breakpoint-only(lg) {
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@include media-breakpoint-only(xl) {
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.toast-top-right {
  padding-top: 70px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Sticky footer styles
  -------------------------------------------------- */

html {
  font-size: 14px;
  background-color: #f4f5fa;
}

table {
  font-family: "Volvo Novum Regular";
  color: #464855;
  border-width: 5%;
  margin-top: 2%;
  margin-left: 0.6%;
}

table th {
  margin-bottom: 0.5rem;
  font-family: "Volvo Novum Regular";
  font-size: 15px;
  color: #5e6e82;
  font-weight: bold;
}

.pagination {
  margin-left: 0.5%;
}

.page-item.active .page-link {
  z-index: 0 !important;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
  -------------------------------------------------- */

/* html {
    position: relative;
    min-height: 100%;
  }

  body {
    /* Margin bottom by footer height */
/* margin-bottom: 40px; */
/*} */

.footer {
  position: absolute;
  padding: 0%;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  height: 60px;
  line-height: 60px;
  font-family: "Volvo Novum Regular";
  font-weight: 500;
  background-color: #fff;
}

input,
select,
file,
textarea {
  max-width: 400px;
}

/* Change the normal behavior of navbar */

.brand-logo {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
}

.brand-title {
  display: inline;
  padding-left: 6px;
  font-weight: 500;
  vertical-align: middle;
  color: #464855;
  font-family: "Volvo Novum Regular";
  font-size: 20px;
}

.brand-title.dealer-title{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.dv-brand-title {
  margin-left: 24px;
  margin-right: 10px;
  margin-top: 14px;
}

.main-header {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}

body {
  letter-spacing: 0.3px;
  line-height: 1.6;
  background-color: #f4f5fa;
  overflow-x: hidden;
}

.ng-alt-sidebar{
  position: fixed !important;
}


.logo img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Volvo Novum Regular";
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: #464855;
}

.bd-masthead {
  padding: 3rem 0;
  background: linear-gradient(165deg, #f7f5fb 50%, #fff 50%);
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile-block {
  position: fixed;
  left: 80%;
  top: 1.5%;
}

.profile-block h5 {
  padding-top: 7%;
}

.profile-block img {
  margin-right: 6px;
}

.breadcrumb h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
}

.brand-title {
  display: inline;
  padding-left: 6px;
  font-weight: 500;
  vertical-align: middle;
  color: #464855;
  font-family: "Volvo Novum Regular";
  font-size: 20px;
}

.separator-breadcrumb {
  margin-bottom: 1rem;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  body{
    padding-top: 70px;
  }
  .bg-wrap {
    padding-top: 60px !important;
  }

  .div-card-principal {
    margin-top: 2%;
    padding: 10% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
  .toast-top-right {
    padding-top: 90px;
  }
  .card{
    top: 50px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  body{
    padding-top: 70px;
  }
  .bg-wrap {
    padding-top: 60px !important;
  }

  .body {
    text-size-adjust: auto;
  }
  .div-card-principal {
    margin-top: 2%;
    padding: 10% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
  .toast-top-right {
    padding-top: 90px;
  }
  .card{
    top: 50px;
  }
}
.contract-accepted-body {
  padding-top: 0 !important;
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  body{
    padding-top: 70px;
  }

  .bg-wrap {
    padding-top: 60px !important;
  }
  .div-card-principal {
    padding-top: 60px;
    margin-top: 2%;
    padding: 2.5% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
  .toast-top-right {
    padding-top: 90px;
  }
  .card{
    top: 50px;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  body{
    padding-top: 70px;
  }
  .div-card-principal {
    padding-top: 60px;
    margin-top: 2%;
    padding: 5% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
  .toast-top-right {
    padding-top: 90px;
  }
}

@media (max-width: 1334px) {
  body{
    padding-top: 70px;
  }

  .div-card-principal {
    padding-top: 60px;
    margin-top: 2%;
    padding: 3.5% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
}

@media (max-width: 1440px) {
  body{
    padding-top: 70px;
  }

  .div-card-principal {
    padding-top: 60px;
    padding: 1.5% 2% 10% 1.7%;
    margin-bottom: 5%;
  }

}

@media (min-width: 1441px) {
  .div-card-principal {
    padding: 1% 2% 10% 1.7%;
    margin-bottom: 5%;
  }
    
  body{
    padding-top: 70px;
  }
}

.div-card-result-principal {
  padding: 2% 2% 0% 1.7%;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.form-group label {
  color: #1b406c;
  margin-bottom: 4px;
  /* font-family: 'Volvo Novum Regular', Georgia, 'Times New Roman', Times, serif;
    font-weight: bold; */
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-family: "Volvo Novum Regular";
  font-size: 15px;
  color: #6c757d !important;
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.9695rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  line-height: 1.5;
  color: #17365c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-color-blue-volvo {
  color: #fff;
  background-color: #003473;
  border-color: #003473;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #001d40;
  border-color: #001733;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background: #003473;
  box-shadow: 0 8px 25px -8px #003473;
  border-color: #003473;
}
.btn-outline-danger {
  color: $red;
  border-color: $red;
  background-color: transparent;
}

.btn-outline-primary {
  color: $blue;
  border-color: $blue;
  background-color: transparent;
}
.btn-outline-primary:hover {
  color: #fff !important;
  border-color: $blue-hover;
  background-color: $blue-hover;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00234d;
  border-color: #001d40;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn-outline-blue:hover {
  color: #fff !important;
  background-color: $blue-hover !important;
}

.btn-outline-blue {
  color: $blue !important;
  border-color: $blue !important;
  background-color: transparent !important;
}

/*Table layout*/

.tbl {
  padding-left: 2%;
  padding-right: 2%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 13px;
}

.breadcrumb-item {
  display: flex;
}

.ativo {
  background-color: #ccf6e4;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #00864e;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.pausado {
  background-color: #f39c12;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.cancelado {
  color: #932338;
  background-color: #fad7dd;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.inativo {
  background-color: #fde6d8;
  color: #c46632;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.multiselect-dropdown {
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multiselect-dropdown .dropdown-btn {
  display: block;
  border: 1px solid #ced4da !important;
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  line-height: 1.5;
  color: #17365c;
  background-color: #fff;
  background-clip: padding-box;
}

.selected-item {
  border: 1px solid #235591 !important;
  margin-right: 4px !important;
  margin-top: 4px !important;
  background: #235591 !important;
  padding: 0px 5px !important;
  color: #fff !important;
  border-radius: 2px !important;
  float: left !important;
  max-width: 160px !important;
}

.multiselect-item-checkbox input[type="checkbox"]:focus + div:before,
.multiselect-item-checkbox input[type="checkbox"]:hover + div:before {
  border-color: #235591 !important;
  background-color: #f2f2f2;
}
.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: #235591 !important;
  border: 2px solid #235591 !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  background-color: #235591 !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: #235591 !important;
}

/* #17365c */

.btn-success {
  color: #fff;
  border-color: $green !important;
  background-color: $green !important;
}
.btn-success:hover {
  color: #fff;
  border-color: $green !important;
  background-color: $green !important;
}

.btn-primary {
  color: #fff;
  border-color: $blue !important;
  background-color: $blue !important;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

iframe {
  height: 900px !important;
}

.badge-primary {
  color: #fff;
  background-color: #004fbc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-success {
  color: #fff;
  background-color: #47962d;
}

.badge-danger {
  color: #fff;
  background-color: #c4001a;
}

::-webkit-scrollbar-thumb {
  background: #004fbc;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0056d6;
}
::-webkit-scrollbar-track {
  background: #464855;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}




.align-text-center {
  text-align: center;
}

@media print {
  body {
  orientation:landscape;
  }

  app-create-checklist {
    .save-checklist-button {
      display:none;
    }

    .print-page-button {
      display:none;
    }

    }



  app-root {
    display:block;
  }

  app-root {
    display:none;
  }

}
