@font-face {
  font-family: "Volvo Novum Regular";
  src: local("Volvo Novum Regular"), url(../assets/fonts/VolvoNovum-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"), url(../assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"), url(../../node_modules/diagnostic-web-components/diag-pdf-reports/fonts-pdf/roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"), url(../../node_modules/diagnostic-web-components/diag-pdf-reports/fonts-pdf/roboto/Roboto-Medium.ttf) format("truetype");
}


